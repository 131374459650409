import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { Link, withPrefix } from 'gatsby'

import Page, { Section } from '../../shared/page-commons'
import SectionHeader from '../../shared/section-header'
import * as defaults from '../../shared/markdown-defaults'
import { Grid, MinimalCard } from '../../shared/card-grid'
import AriadnaBanner from './_ariadna-banner'

const Image = styled.img`
  width: 100%;
  display: block;
`

export default function AriadnaOverviewPage(props) {
  return (
    <Page bgColor="surface">
      <Helmet>
        <meta
          name="description"
          content="Ariadna is a machanism for collaborative joint research between the ACT and academia."
        />
        <meta
          property="og:description"
          content="Ariadna is a machanism for collaborative joint research between the ACT and academia."
        />
        <meta
          name="twitter:description"
          content="Ariadna is a machanism for collaborative joint research between the ACT and academia."
        />
      </Helmet>
      <AriadnaBanner />
      <Section textColumn>
        <SectionHeader>Ariadna</SectionHeader>
        <defaults.p>
          ... strengthening the links between ESA and the European academic
          community since the dawn of the millenium.
        </defaults.p>
        <Image src={withPrefix('/images/core/infographic-adriadna.png')} />
      </Section>
      <Section bgColor="background" textColumn>
        <Grid>
          <MinimalCard
            src="/images/core/ariadna_about.png"
            to="/collab/ariadna_about"
            call="About"
          />
          <MinimalCard
            src="/images/core/ariadna_calls.png"
            to="/collab/ariadna_calls"
            call="Calls"
          />
          <MinimalCard
            src="/images/core/ariadna_search.png"
            to="/collab/ariadna_search"
            call="Search studies"
          />
        </Grid>
        <defaults.p css="margin-top: 50px;">
          Check also the{' '}
          <defaults.a as={Link} to="/collab/ariadna_faq">
            FAQ pages
          </defaults.a>{' '}
          for further info.
        </defaults.p>
      </Section>
    </Page>
  )
}
